import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';

import type { Icon } from '@rover/icons';
import { ReviewStar, ReviewStarFill, ReviewStarHalf, ReviewStarHalfOutline } from '@rover/icons';
import type { ThemeFontWeight, ThemeTextColor, ThemeTextSizes } from '@rover/kibble/styles';
import { Color, DSTokenMap } from '@rover/kibble/styles';

export type StarRatingCommonProps = {
  rating: number;
  size?: number;
  textSize?: ThemeTextSizes;
  fontWeight?: ThemeFontWeight;
  variant?: StarRatingVariant;
  maxRating?: number;
  disabled?: boolean;
  textColor?: ThemeTextColor;
  emptyValue?: string;
};

export type StarRatingComponentType<Props extends StarRatingCommonProps> = (
  props: Props
) => JSX.Element;

export const STAR_SIZE = 16;

export type StarRatingVariant = 'expand' | 'outline' | 'compact';

type StarProps = {
  size: number;
  disabled: boolean;
};

export const getAccessibilityLabel = (
  starRating: string | null,
  maxRating: string
): MessageDescriptor => {
  if (starRating === null) return t`No star rating available`;
  return t`${starRating} out of ${maxRating} stars`;
};

export const getStarRating = (rating: number, maxRating: number): number => {
  let stars = rating;
  if (stars < 0) {
    stars = 0;
  } else if (stars > maxRating) {
    stars = maxRating;
  }
  return stars;
};

export const FullStar = ({ size, disabled }: StarProps): Icon => (
  <ReviewStarFill
    height={size}
    width={size}
    fill={disabled ? DSTokenMap.TEXT_COLOR_DISABLED.toString() : Color.YELLOW_400.toString()}
  />
);

export const HalfStar = ({ size, disabled }: StarProps): Icon => (
  <ReviewStarHalf
    height={size}
    width={size}
    fill={disabled ? DSTokenMap.TEXT_COLOR_DISABLED.toString() : Color.YELLOW_400.toString()}
  />
);

export const FullStarOutline = ({ size, disabled }: StarProps): Icon => (
  <ReviewStar
    height={size}
    width={size}
    fill={disabled ? DSTokenMap.TEXT_COLOR_DISABLED.toString() : Color.YELLOW_400.toString()}
  />
);

export const HalfStarOutline = ({ size, disabled }: StarProps): Icon => (
  <ReviewStarHalfOutline
    height={size}
    width={size}
    fill={disabled ? DSTokenMap.TEXT_COLOR_DISABLED.toString() : Color.YELLOW_400.toString()}
  />
);

export const getStars = (
  numWholeStars: number,
  numEmptyStars: number,
  hasHalfStar: boolean,
  outline: boolean,
  size: number,
  disabled: boolean
): Icon[] => {
  const stars: JSX.Element[] = [];

  // Add full stars
  for (let i = 0; i < numWholeStars; i += 1) {
    stars.push(<FullStar size={size} disabled={disabled} key={`full-star-${i}`} />);
  }

  // Add half stars
  if (hasHalfStar) {
    if (outline) {
      stars.push(<HalfStarOutline size={size} disabled={disabled} key="half-star-outline" />);
    } else {
      stars.push(<HalfStar size={size} disabled={disabled} key="half-star" />);
    }
  }

  // Add empty stars
  if (outline) {
    for (let i = 0; i < numEmptyStars; i += 1) {
      stars.push(
        <FullStarOutline size={size} disabled={disabled} key={`full-star-outline-${i}`} />
      );
    }
  }

  return stars;
};

export const generateStars = (
  variant: StarRatingVariant,
  rating: number | null,
  maxRating: number,
  disabled: boolean
): Icon[] => {
  const starSize = STAR_SIZE;

  if (variant === 'compact') {
    return [<FullStar size={starSize} key="compact-star-0" disabled={disabled} />];
  }

  if (rating === null) return [];

  const hasHalfStar = rating % 1 !== 0;
  const numWholeStars = Math.floor(rating);
  const numEmptyStars = maxRating - Math.ceil(rating);

  if (variant === 'expand') {
    return getStars(numWholeStars, numEmptyStars, hasHalfStar, false, starSize, disabled);
  }

  if (variant === 'outline') {
    return getStars(numWholeStars, numEmptyStars, hasHalfStar, true, starSize, disabled);
  }

  return [];
};
