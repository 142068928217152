import React from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Close } from '@rover/icons';
import { Box, Button, Flex } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { CLASS_NAMES } from '@rover/react-lib/src/constants/classNames.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isTranslatable } from '@rover/utilities/i18n';

import {
  AlertSeverity,
  defaultProps,
  getBackgroundColorForSeverity,
  getBorderColorForSeverity,
} from './Alert.common';
import type { Props as CommonProps } from './Alert.types';

export type Props = CommonProps & {
  className?: string;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  dismissIconTopRight?: boolean; // if true, dismiss icon will be placed on top right corner vs default center right
};

const DismissIcon = styled(Close)`
  cursor: pointer;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  height: ${DSTokenMap.SPACE_4X};
  width: ${DSTokenMap.SPACE_4X};

  &:hover {
    fill: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  }
`;

const DismissButton = ({ onDismiss }: { onDismiss?: () => void }) => {
  const { i18n } = useI18n();

  if (!onDismiss) return null;

  return (
    <Button
      width="4x"
      role="button"
      variant="flat"
      sx={{ ':hover': { background: 'none' } }}
      icon={DismissIcon}
      aria-label={i18n._('Close Alert')}
      onClick={onDismiss}
    />
  );
};

const Alert = ({
  children,
  onDismiss,
  severity = defaultProps.severity,
  className = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerRef,
  dismissIconTopRight = false,
  ...other
}: Props): JSX.Element | null => {
  if (!children) return null;
  return (
    <Box
      display="flex"
      padding="4x"
      mb="6x"
      borderWidth="0x !important"
      borderStyle="solid !important"
      borderRadius="4x"
      sx={{
        backgroundColor: getBackgroundColorForSeverity(severity),
        borderColor: getBorderColorForSeverity(severity),
      }}
      severity={severity}
      className={`${CLASS_NAMES.ALERT_CLASSNAME} ${className}`}
      {...other}
    >
      <Flex flex="1" id="icon-wrapper">
        {isTranslatable(children) ? <Trans id={children} /> : children}
      </Flex>
      <Flex flexDirection={dismissIconTopRight ? 'column' : 'row'}>
        <DismissButton onDismiss={onDismiss} />
      </Flex>
    </Box>
  );
};

// attach AlertSeverity to default export for convenience
Alert.Severity = AlertSeverity;

export { AlertSeverity };

export default Alert;
