import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';

import type { Icon as IconType } from '@rover/icons';
import { AlertCaution, AlertInfo, AlertSuccess, TrustLogo } from '@rover/icons';
import { DSTokenMap, ThemeBackgroundColors } from '@rover/kibble/styles';

import { AlertSeverity } from './AlertSeverity';

export { AlertSeverity };

export const AlertSeverityLabel: Record<AlertSeverity, MessageDescriptor> = {
  [AlertSeverity.DANGER]: /* i18n: alert severity */ t`danger`,
  [AlertSeverity.WARNING]: /* i18n: alert severity */ t`warning`,
  [AlertSeverity.SUCCESS]: /* i18n: alert severity */ t`success`,
  [AlertSeverity.INFO]: /* i18n: alert severity */ t`info`,
  [AlertSeverity.SYSTEM]: /* i18n: alert severity */ t`system`,
};

const ALERT_SEVERITY_BACKGROUND_COLORS: Record<AlertSeverity, string> = {
  [AlertSeverity.DANGER]: DSTokenMap.BACKGROUND_COLOR_ERROR.toString(),
  [AlertSeverity.WARNING]: DSTokenMap.BACKGROUND_COLOR_CAUTION.toString(),
  [AlertSeverity.SUCCESS]: DSTokenMap.BACKGROUND_COLOR_SUCCESS.toString(),
  [AlertSeverity.INFO]: DSTokenMap.BACKGROUND_COLOR_HIGHLIGHT.toString(),
  [AlertSeverity.SYSTEM]: DSTokenMap.BACKGROUND_COLOR_ACCENT_SECONDARY.toString(),
};

const ALERT_SEVERITY_KIBBLE_BACKGROUND: Record<AlertSeverity, ThemeBackgroundColors> = {
  [AlertSeverity.DANGER]: 'error',
  [AlertSeverity.WARNING]: 'caution',
  [AlertSeverity.SUCCESS]: 'success',
  [AlertSeverity.INFO]: 'highlight',
  [AlertSeverity.SYSTEM]: 'info',
};

const ALERT_SEVERITY_BORDERS: Record<AlertSeverity, string> = {
  [AlertSeverity.DANGER]: DSTokenMap.BORDER_COLOR_ERROR.toString(),
  [AlertSeverity.WARNING]: DSTokenMap.BORDER_COLOR_CAUTION.toString(),
  [AlertSeverity.SUCCESS]: DSTokenMap.BORDER_COLOR_SUCCESS.toString(),
  [AlertSeverity.INFO]: DSTokenMap.BORDER_COLOR_HIGHLIGHT.toString(),
  [AlertSeverity.SYSTEM]: DSTokenMap.BORDER_COLOR_INPUT_FOCUS.toString(),
};

const ALERT_SEVERITY_ICONS: Record<AlertSeverity, React.ComponentType<IconType>> = {
  [AlertSeverity.DANGER]: AlertCaution,
  [AlertSeverity.WARNING]: AlertInfo,
  [AlertSeverity.SUCCESS]: AlertSuccess,
  [AlertSeverity.INFO]: AlertInfo,
  [AlertSeverity.SYSTEM]: TrustLogo,
};

export const getBackgroundColorForSeverity = (severity: AlertSeverity): string => {
  const severityBackground = ALERT_SEVERITY_BACKGROUND_COLORS[severity];
  if (severityBackground) return severityBackground;
  return ALERT_SEVERITY_BACKGROUND_COLORS[AlertSeverity.INFO];
};

export const getKibbleBackgroundForSeverity = (severity: AlertSeverity): ThemeBackgroundColors => {
  const severityBackground = ALERT_SEVERITY_KIBBLE_BACKGROUND[severity];
  if (severityBackground) return severityBackground;
  return ALERT_SEVERITY_KIBBLE_BACKGROUND[AlertSeverity.INFO];
};

export const getBorderColorForSeverity = (severity: AlertSeverity): string => {
  const severityBorder = ALERT_SEVERITY_BORDERS[severity];
  if (severityBorder) return severityBorder;
  return ALERT_SEVERITY_BORDERS[AlertSeverity.INFO];
};

export const getIconForSeverity = (severity: AlertSeverity): React.ComponentType<IconType> => {
  const severityIcon = ALERT_SEVERITY_ICONS[severity];
  if (severityIcon) return severityIcon;
  return ALERT_SEVERITY_ICONS[AlertSeverity.INFO];
};

export const defaultProps = {
  severity: AlertSeverity.INFO,
  onDismiss: undefined,
};
