import React from 'react';
import styled, { keyframes } from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type OuterCircleProps = { diameter: number };

// Adapted from https://github.com/tobiasahlin/SpinKit/blob/master/examples/8-circle.html
const OuterCircle = styled.div<OuterCircleProps>`
  position: relative;
  height: ${(props) => props.diameter}px;
  width: ${(props) => props.diameter}px;
`;
const dotAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

type DotProps = { number: number; color: string };

const Dot = styled.div<DotProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(${(props) => 30 * props.number}deg);

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${(props) => props.color};
    border-radius: 100%;
    animation: ${dotAnimation} 1.2s infinite ease-in-out both;
    animation-delay: ${(props) => 0.1 * props.number}s;
  }
`;

export type Props = {
  diameter?: number;
  color?: string;
};

const AnimatedSpinner = ({
  diameter = 40,
  color = DSTokenMap.TEXT_COLOR_TERTIARY.toString(),
  ...other
}: Props): JSX.Element => (
  <Wrapper {...other}>
    <OuterCircle diameter={diameter}>
      <Dot number={0} color={color} />
      <Dot number={1} color={color} />
      <Dot number={2} color={color} />
      <Dot number={3} color={color} />
      <Dot number={4} color={color} />
      <Dot number={5} color={color} />
      <Dot number={6} color={color} />
      <Dot number={7} color={color} />
      <Dot number={8} color={color} />
      <Dot number={9} color={color} />
      <Dot number={10} color={color} />
      <Dot number={11} color={color} />
    </OuterCircle>
  </Wrapper>
);

export default AnimatedSpinner;
