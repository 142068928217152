import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

const DEFAULT_OFFSET = 100;

const Placeholder = styled.div<{
  imageHeight?: string;
  imageWidth?: string;
  backgroundColor?: string;
}>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  height: ${(props) => (props.imageHeight ? `${props.imageHeight}px` : '')};
  width: ${(props) => (props.imageWidth ? `${props.imageWidth}px` : '')};
`;

type Props = {
  src: string;
  alt: string;
  ariaLabel?: string;
  height?: string;
  width?: string;
  lazy?: boolean;
  offset?: number;
  throttle?: number;
  backgroundColor?: string;
  role?: string;
};

type State = {
  loaded: boolean;
};

export default class Image extends PureComponent<Props, State> {
  element!: HTMLElement;

  observer!: IntersectionObserver;

  static defaultProps = {
    lazy: true,
    offset: DEFAULT_OFFSET,
    throttle: 0,
    width: 'auto',
  };

  state = {
    loaded: false,
  };

  componentDidMount(): void {
    if (this.props.lazy && typeof IntersectionObserver === 'function') {
      this.observer = new IntersectionObserver(
        (entries) => {
          const image = entries[0];
          if (image.isIntersecting) {
            this.load();
            this.observer.disconnect();
          }
        },
        {
          root: null,
          rootMargin: `0px 0px ${this.props.offset || DEFAULT_OFFSET}px 0px`,
          threshold: 0.1,
        }
      );

      this.observer.observe(this.element);
    }
  }

  componentWillUnmount(): void {
    if (this.props.lazy && this.observer) {
      this.observer.disconnect();
    }
  }

  load(): void {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, this.props.throttle);
  }

  render(): JSX.Element {
    const { alt, ariaLabel, height, lazy, src, width, backgroundColor, role, ...other } =
      this.props;
    const { loaded } = this.state;

    const img = (
      <img
        alt={alt}
        aria-label={ariaLabel}
        height={height}
        src={src}
        width={width}
        role={role}
        {...other}
      />
    );

    if (!loaded && lazy) {
      return (
        <Placeholder
          ref={(el) => {
            if (el) this.element = el;
          }}
          imageWidth={width}
          imageHeight={height}
          backgroundColor={backgroundColor}
          {...other}
        >
          <noscript>{img}</noscript>
        </Placeholder>
      );
    }

    return img;
  }
}
