import React from 'react';
import { MessageDescriptor } from '@lingui/core';

import { Box, Text } from '@rover/kibble/core';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { A11yHiddenBox } from '../../styles';

import {
  generateStars,
  getAccessibilityLabel,
  getStarRating,
  StarRatingCommonProps,
} from './StarRating.common';

export type Props = StarRatingCommonProps & {
  /** Text to appear alongside the numeric rating. Only shows in `"compact"` variant */
  ratingText?: MessageDescriptor;
  onClick?: () => void;
};

export const StarRating = ({
  rating,
  variant = 'compact',
  maxRating = 5,
  textSize = '200',
  fontWeight = 'regular',
  disabled = false,
  ratingText,
  onClick,
  emptyValue,
  ...other
}: Props): JSX.Element => {
  const starRating = getStarRating(rating, maxRating);
  const stars: JSX.Element[] = generateStars(
    variant,
    emptyValue ? null : starRating,
    maxRating,
    disabled
  );
  const { i18n } = useI18n();
  const accessiblityLabel = i18n._(
    getAccessibilityLabel(
      maxRating.toFixed(0),
      starRating === undefined ? starRating : starRating.toFixed(1)
    )
  );
  const translatedRatingText = ratingText ? i18n._(ratingText) : undefined;
  const isClickable = !!onClick;

  return (
    <Box {...other} display="flex" flexDirection="row" alignItems="center">
      <A11yHiddenBox>{accessiblityLabel}</A11yHiddenBox>
      <Box aria-hidden display="flex" flexDirection="row">
        {stars}
      </Box>
      <Box display="flex">
        {variant === 'compact' && (
          <Text
            aria-hidden
            textDecoration={isClickable ? 'underline' : 'none'}
            onClick={onClick}
            paddingLeft="1x"
            size={textSize}
            fontWeight={fontWeight}
            textColor={disabled ? 'disabled' : 'primary'}
            sx={{ cursor: isClickable ? 'pointer' : 'default' }}
          >
            {emptyValue ?? starRating.toFixed(1)}
            {translatedRatingText && ` ${translatedRatingText}`}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default StarRating;
