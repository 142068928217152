import type { ReactElement } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Favorite, FavoriteFill } from '@rover/icons';
import { Button } from '@rover/kibble/core';
import type { ButtonVariants } from '@rover/kibble/core/Button';
import { DSTokenMap } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

type FavoriteButtonVariant = 'flat' | 'default';

export interface Props {
  on?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
  variant?: FavoriteButtonVariant;
}

const FavoriteButtonVariant = {
  flat: {
    buttonVariant: (): ButtonVariants => 'flat',
    buttonStyles: (on: boolean): Record<string, unknown> =>
      on
        ? { color: `${DSTokenMap.TEXT_COLOR_SUCCESS.toString()} !important` }
        : {
            color: DSTokenMap.TEXT_COLOR_PRIMARY.toString(),
            '&:hover': {
              color: DSTokenMap.TEXT_COLOR_PRIMARY.toString(),
            },
            '&:focus': {
              color: DSTokenMap.TEXT_COLOR_PRIMARY.toString(),
            },
          },
  },
  default: {
    buttonVariant: (on: boolean): ButtonVariants => (on ? 'primary' : 'default'),
    buttonStyles: (): Record<string, unknown> => ({
      color: DSTokenMap.TEXT_COLOR_PRIMARY.toString(),
      '&:hover': {
        color: DSTokenMap.TEXT_COLOR_PRIMARY.toString(),
      },
    }),
  },
};

const FavoriteFillHatch = styled(FavoriteFill)`
  @keyframes hatch {
    0% {
      transform: rotate(0deg) scaleY(0.6);
    }
    20% {
      transform: rotate(-2deg) scaleY(1.05);
    }
    35% {
      transform: rotate(2deg) scaleY(1);
    }
    50% {
      transform: rotate(-2deg);
    }
    65% {
      transform: rotate(1deg);
    }
    80% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  animation: hatch 2s ease-in-out;
  transform-origin: 50% 100%;
  visibility: visible !important;
`;

function usePrevious(value): boolean | undefined {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function FavoriteButton({
  on = false,
  variant = 'default',
  onClick,
  ...other
}: Props): ReactElement {
  const { i18n } = useI18n();

  const [favoriting, setFavoriting] = useState(false);
  const prevFavoriting = usePrevious(favoriting);

  const favoriteButtonVariant = FavoriteButtonVariant[variant];

  const getFavoriteIcon = (): React.ComponentType<unknown> => {
    if (on) {
      if (favoriting && !prevFavoriting) {
        return FavoriteFillHatch;
      }
      return FavoriteFill;
    }
    return Favorite;
  };
  return (
    <Button
      {...other}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setFavoriting(!on);
        onClick?.(e);
      }}
      aria-label={i18n._('Add this sitter to my favorites')}
      aria-pressed={on}
      padding="2x"
      icon={getFavoriteIcon()}
      size="small"
      variant={favoriteButtonVariant.buttonVariant(on)}
      sx={favoriteButtonVariant.buttonStyles(on)}
    />
  );
}
