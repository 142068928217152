import styled, { css } from 'styled-components';

import { ZIndex } from '@rover/kibble/styles';

import './RoverLoading.types';

import roverLoader from './rover-loader-animation.svg?url';
import type { Props as CommonProps } from './RoverLoading.types';

const SIZE = 40;
type RoverLoadingProps = {
  isLoading: boolean;
};
const RoverLoading = styled.div<RoverLoadingProps>`
  ${({ isLoading }) =>
    !isLoading
      ? ''
      : css`
          position: relative;

          &:before,
          &:after {
            position: absolute;
            display: block;
            content: ' ';
          }

          &:before {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: ${ZIndex.NAVBAR_FIXED.toNumber() - 2};
          }

          &:after {
            display: inline-block;
            background-image: url(${roverLoader});
            background-size: ${SIZE}px ${SIZE}px;
            width: ${SIZE}px;
            height: ${SIZE}px;
            top: 50%;
            left: 50%;
            z-index: ${ZIndex.NAVBAR_FIXED.toNumber() - 1};
            margin-top: ${(SIZE / 2) * -1}px;
            margin-left: ${(SIZE / 2) * -1}px;
          }
        `}
`;
export type Props = CommonProps & {
  // eslint's require-default-props rule doesn't seem to play well with hyphens.
  // eslint-disable-next-line react/require-default-props
  'data-qa-id'?: string;
};
export default RoverLoading;
