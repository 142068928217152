import type { ReactElement } from 'react';
import React, { memo } from 'react';
import type { ReactPaginateProps } from 'react-paginate';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import { CarouselLeft, CarouselRight } from '@rover/icons';
import { DSTokenMap } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type Props = ReactPaginateProps;

export const PaginationWrapper = styled.nav`
  user-select: none;

  ul {
    display: flex;
    padding: 0;
  }

  li {
    align-items: center;
    border: 1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    min-height: 40px;
    min-width: 44px;

    &:not(:last-child) {
      margin-right: -1px;
    }

    &:first-child,
    &:first-child > a {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child,
    &:last-child > a {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    & > a {
      position: relative;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
      width: 44px;
      height: 40px;
      text-decoration: none;
    }

    &.selected > a {
      padding-top: 2px;
      border-bottom: 2px solid ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()};
      color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
      font-weight: bold;
    }

    &.disabled {
      cursor: not-allowed;

      & a > svg {
        fill: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()};
      }
    }
  }
`;

const LeftArrow = styled(CarouselLeft)`
  width: 16px;
  height: 16px;
`;
const RightArrow = styled(CarouselRight)`
  width: 16px;
  height: 16px;
`;

const blur = ({ target }) => {
  setTimeout(() => {
    target.blur();
    target.parentNode.blur();
  });
};

const Pagination = ({ ...props }: Props): ReactElement => {
  const { i18n } = useI18n();

  return (
    <PaginationWrapper onMouseDown={blur} onMouseUp={blur} aria-label={i18n._('Pagination')}>
      <ReactPaginate
        previousLabel={<LeftArrow />}
        previousAriaLabel={i18n._('Previous page')}
        breakLabel={<div aria-label={i18n._('Other pages')}>...</div>}
        nextLabel={<RightArrow />}
        nextAriaLabel={i18n._('Next page')}
        {...props}
      />
    </PaginationWrapper>
  );
};

Pagination.defaultProps = {
  marginPagesDisplayed: 0,
};
export default memo(Pagination);
